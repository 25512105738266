import { Component, Host, Prop, State, Element, Fragment, h } from '@stencil/core';
import clsx from 'clsx';

@Component({
  tag: 'x-aside',
  styleUrl: 'x-aside.sass',
})
export class XAside {
  @Element() el: HTMLElement;

  @Prop() container: boolean;
  @Prop() item: boolean;
  @Prop() selected: boolean;
  @Prop() icon: string;
  @Prop() label: string;
  @Prop() href: string;

  @State() open: boolean = false;

  private toggleSubmenu = e => {
    const target = document.querySelector('.x-aside__sub');
    const targetContent = document.querySelector('.x-aside__sub-content');
    const isOpen = this.el.getAttribute('open') !== null;

    if (isOpen) {
      this.el.removeAttribute('open');
      targetContent.innerHTML = '';
      target.setAttribute('hidden', '');
    } else {
      document.querySelector('.x-aside__item[open]')?.removeAttribute('open');
      this.el.setAttribute('open', '');
      targetContent.innerHTML = e.currentTarget.nextElementSibling.innerHTML;
      target.removeAttribute('hidden');
    }
  };

  private closeSubmenu = () => {
    document.querySelector('.x-aside__sub').setAttribute('hidden', '');
    document.querySelector('.x-aside__item[open]')?.removeAttribute('open');
  };

  render() {
    if (this.container) {
      return (
        <Host class="x-aside">
          <div class="x-aside__main">
            <slot></slot>
          </div>
          <div class="x-aside__sub" hidden>
            <button type="button" class="x-aside__sub-close" onClick={this.closeSubmenu}>
              <x-icon glyph="aside-close"></x-icon>
            </button>
            <div class="x-aside__sub-content"></div>
          </div>
        </Host>
      );
    } else if (this.item) {
      const inner = (
        <Fragment>
          <figure>
            <x-icon glyph={this.icon}></x-icon>
          </figure>
          <figcaption>{this.label}</figcaption>
        </Fragment>
      );

      return (
        <Host class={clsx('x-aside__item', { '-state-selected': this.selected || this.open })}>
          {!!this.href ? (
            <a href={this.href} class="x-aside__item-handle">
              {inner}
            </a>
          ) : (
            <button type="button" class="x-aside__item-handle" onClick={this.toggleSubmenu}>
              {inner}
            </button>
          )}
          <div hidden>
            <slot></slot>
          </div>
        </Host>
      );
    } else {
      return null;
    }
  }
}
