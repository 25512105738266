.x-aside
  display: flex
  height: 100%
  &__main
    flex: 0 0 auto
    display: flex
    flex-direction: column
    align-items: center
    width: 80px
    height: 100%
    background-color: var(--primary-white)
    box-shadow: 4px 0px 8px rgba(0, 0, 0, 0.0495)
    overflow: auto
    position: relative
    z-index: 2
  &__sub
    flex: 0 0 auto
    width: 214px
    height: 100%
    overflow: auto
    background-color: var(--primary-grey)
    box-shadow: 4px 0px 8px rgba(0, 0, 0, 0.0495)
    position: relative
    z-index: 1
    &-close
      display: block
      position: absolute
      top: 5px
      right: 5px
      width: 16px
      height: 16px
      padding: 3px
      color: var(--icon-dark-50)
      border: none
      background: none
      font-size: 0
      line-height: 0
      &:hover
        color: var(--icon-primary)
    dl
      +preset(3)
      width: 100%
      padding: 12px 24px 32px
      box-sizing: border-box
      margin: 0
      &:hover
        display: block
      dt
        padding: 21px 0 0
        margin: 0 0 -1px
        color: var(--on-primary-dark-50)
      dd
        font-weight: 600
        padding: 0
        margin: 0
        border-bottom: 1px solid var(--divider-dark-grey)
        a
          display: block
          padding: 21px 0 19px
          color: var(--on-primary-dark)
          &[aria-selected],
          &:hover
            color: var(--on-primary-green)
  &__item
    display: block
    width: 100%
    & + &:not(:last-child):before
      content: ""
      display: block
      width: 48px
      height: 1px
      background-color: var(--divider-dark-grey)
      margin: auto
      position: relative
    &:last-child
      margin-top: auto
    &-handle
      display: flex
      flex-direction: column
      align-items: center
      justify-content: center
      width: 100%
      height: 77px
      border: none
      background: none
      position: relative
      color: var(--on-primary-dark-50)
      &:hover
        color: var(--on-primary-green)
    figure
      width: 32px
      height: 32px
      margin-bottom: 2px
      font-size: 0
      line-height: 0
    figcaption
      +preset(5)
    &[selected] &-handle,
    &[open] &-handle
      color: var(--on-primary-green)
